import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Switch from '@mui/material/Switch';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { useBoolean } from 'src/hooks/use-boolean';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';

import { bgBlur } from 'src/theme/css';
import { featAppDarkMode } from 'src/configs/feature-flags';

import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';

import Searchbar from '../common/searchbar';
import { NAV, HEADER } from '../config-layout';
import SettingsButton from '../common/settings-button';
import AccountPopover from '../common/account-popover';
import ContactsPopover from '../common/contacts-popover';
import LanguagePopover from '../common/language-popover';
import NotificationsPopover from '../common/notifications-popover';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const mdUp = useResponsive('up', 'md');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const themeMode = useBoolean(settings.themeMode === 'dark');

  const renderMode = featAppDarkMode.isEnabled && (
    <Stack component='label' sx={{ flexDirection: 'row' }}>
      <SvgColor
        src={`/assets/icons/setting/ic_${settings.themeMode === 'dark' ? 'moon' : 'sun'}.svg`}
        sx={{ cursor: 'pointer' }}
      />
      <Switch
        size='small'
        checked={themeMode.value}
        onChange={(_, checked: boolean) => {
          themeMode.onToggle();
          settings.onUpdate('themeMode', checked ? 'dark' : 'light');
        }}
      />
    </Stack>
  );

  const renderContent = (
    <>
      {mdUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!mdUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src='/assets/icons/navbar/ic_menu_item.svg' />
        </IconButton>
      )}

      <Searchbar />

      <Stack flexGrow={1} direction='row' alignItems='center' justifyContent='flex-end' spacing={{ xs: 0.5, sm: 1 }}>
        {/* TODO: show header icons later */}
        {false && (
          <>
            <LanguagePopover />

            <NotificationsPopover />

            <ContactsPopover />

            <SettingsButton />
          </>
        )}

        {renderMode}

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({ color: theme.palette.background.default }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(mdUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { md: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
