import Map from 'ol/Map';
import View from 'ol/View';
import { Pixel } from 'ol/pixel';
import TileLayer from 'ol/layer/Tile';
import GeoJSON from 'ol/format/GeoJSON';
import Zoomify from 'ol/source/Zoomify';
import { OverviewMap } from 'ol/control';
import { defaults } from 'ol/interaction';
import CircleStyle from 'ol/style/Circle';
import { Coordinate } from 'ol/coordinate';
import VectorSource from 'ol/source/Vector';
import { randomPolygon } from '@turf/random';
import { Fill, Style, Stroke } from 'ol/style';
import Feature, { FeatureLike } from 'ol/Feature';
import booleanIntersects from '@turf/boolean-intersects';
import { Point, Polygon, Geometry, MultiPolygon } from 'ol/geom';
import { Layer, Heatmap, Vector as VectorLayer } from 'ol/layer';
import { Extent, getTopLeft, getTopRight, getBottomLeft, getBottomRight, getArea as getExtentArea } from 'ol/extent';
import {
  BBox,
  polygon,
  Position,
  lineString,
  Feature as TurfFeature,
  Polygon as TurfPolygon,
  MultiPolygon as TurfMultiPolygon,
} from '@turf/helpers';

import { alpha } from '@mui/material/styles';

import { Params } from 'src/helpers/mapper-route-placeholder';
import { Annotation, AnnotationStyle } from 'src/services/canvas/types/types-canvas';
import { featCanvasOverviewMap, featCanvasAdaptiveZoom } from 'src/configs/feature-flags';
import {
  configCanvasViewer,
  configCanvasHeatmap,
  CANVAS_OVERVIEW_MAP_ID,
  configCanvasAdaptiveZoom,
} from 'src/configs/config-canvas';

import HeatmapLayer from 'src/sections/common/components/canvas/heatmap-layer';

import { IDeepZoomImage } from 'src/types/model/dzi';

// ----------------------------------------------------------------------

const geoFormat = new GeoJSON();

// ----------------------------------------------------------------------

export function convertGeoJsonToFeature(geo: TurfFeature<unknown>): Feature<Geometry> {
  const geoJson = geo as TurfFeature<TurfPolygon | TurfMultiPolygon>;

  const { type } = geoJson.geometry;

  if (type !== 'MultiPolygon' && type !== 'Polygon') {
    return geoFormat.readFeature(geoJson);
  }

  return geoFormat.readFeature(geoJson) as Feature<Polygon | MultiPolygon>;
}

export function convertFeatureToGeoJson(feature: Feature<Geometry>): TurfFeature<TurfPolygon | TurfMultiPolygon> {
  const geoJson = geoFormat.writeFeatureObject(feature) as TurfFeature<TurfPolygon | TurfMultiPolygon>;

  return { ...geoJson, properties: {} };
}

// ----------------------------------------------------------------------

export function generateRandomPolygons(size: 'lg' | 'md' | 'sm', count: number, extent: Extent) {
  const bbox: BBox = [extent[0], extent[1], extent[2], extent[3]];
  let maxRadialLength: number;

  switch (size) {
    case 'sm':
      maxRadialLength = 70; // Smaller variations
      break;
    case 'md':
      maxRadialLength = 200; // Moderate variations
      break;
    case 'lg':
      maxRadialLength = 500; // Larger variations
      break;
    default:
      throw new Error('Invalid size specified');
  }

  const polygons: TurfFeature<TurfPolygon, any>[] = [];

  for (let i = 0; i < count; i += 1) {
    const p = randomPolygon(1, {
      num_vertices: Math.floor(Math.random() * 10) + 3, // Random vertices between 3 and 12
      bbox,
      max_radial_length: maxRadialLength,
    });
    polygons.push(p.features[0]);
  }

  return polygons;
}

// ----------------------------------------------------------------------

export function initializeMap(
  target: string | HTMLElement,
  layer?: Layer,
  overviewMapLayer?: Layer
): { map: Map; extent: Extent; paddedExtent: Extent; overviewMapControl?: OverviewMap } {
  const extent = layer?.getExtent() ?? [0, 0, 0, 0];
  const [minX, minY, maxX, maxY] = extent;

  // Add padding to the extent (DZI View)
  const paddingX = (maxX - minX) * configCanvasViewer.paddingRatio;
  const paddingY = (maxY - minY) * configCanvasViewer.paddingRatio;
  const paddedExtent = [minX - paddingX / 2, minY - paddingY / 2, maxX + paddingX / 2, maxY + paddingY / 2];

  const controls = [];
  let overviewMapControl: OverviewMap | undefined;
  if (featCanvasOverviewMap.isEnabled) {
    overviewMapControl = new OverviewMap({
      layers: overviewMapLayer ? [overviewMapLayer] : undefined,
      view: new View({
        zoom: 0,
        minZoom: 0,
        maxZoom: 20,
        showFullExtent: true,
        extent: [minX, minY, maxX, maxY],
        center: [(maxX + minX) / 2, (maxY + minY) / 2],
      }),
      collapsed: false,
      collapsible: true,
      target: CANVAS_OVERVIEW_MAP_ID,
    });

    controls.push(overviewMapControl);
  }

  const map = new Map({
    target,
    layers: layer ? [layer] : undefined,
    view: new View({
      zoom: 0,
      minZoom: 0,
      maxZoom: 20,
      showFullExtent: true,
      extent: paddedExtent,
      center: [(maxX + minX) / 2, (maxY + minY) / 2],
    }),
    controls,
    interactions: defaults({
      doubleClickZoom: false,
    }),
  });

  return { map, extent, paddedExtent, overviewMapControl };
}

export function createDziLayer(dzi: IDeepZoomImage) {
  const width = parseInt(`${dzi.sizeX}`, 10);
  const height = parseInt(`${dzi.sizeY}`, 10);
  const tileSize = parseInt(`${dzi.tileX}`, 10);

  const source = new Zoomify({
    url: '',
    size: [width, height],
    tileSize,
    crossOrigin: 'anonymous',
  });

  const tileUrl = `${dzi.url}/{z}/{y}/{x}.jpg`;
  source.setTileUrlFunction(([z, x, y]: number[]) =>
    tileUrl.replace('{z}', z.toString()).replace('{x}', x.toString()).replace('{y}', y.toString())
  );

  const extent = [0, -height, width, 0];

  const layer = new TileLayer({
    extent,
    source,
    zIndex: 0,
    preload: 3,
    opacity: 1,
    visible: true,
  });
  return { layer, source };
}

export function createCanvasLayer(
  style: AnnotationStyle = configCanvasViewer.style.layer,
  idx = configCanvasViewer.style.zIndex
) {
  const source = new VectorSource();

  const layer = new VectorLayer({
    source,
    style: getLayerStyle(style),
    zIndex: idx,
  });

  return { layer, source };
}

export function getLayerStyle({
  strokeColor = configCanvasViewer.style.layer.strokeColor,
  strokeWidth = configCanvasViewer.style.layer.strokeWidth,
  fillColor = configCanvasViewer.style.layer.fillColor,
}: AnnotationStyle) {
  return new Style({
    fill: new Fill({ color: fillColor }),
    stroke: new Stroke({ color: strokeColor, width: strokeWidth }),
    image: new CircleStyle({
      radius: 4,
      fill: new Fill({
        color: '#ffcc33',
      }),
    }),
  });
}

export function generateHeatmapLayer(annotations: Annotation[]): Heatmap<Feature<Point>> {
  const heatmapLayer = new HeatmapLayer(annotations, configCanvasHeatmap.style.radius, configCanvasHeatmap.style.blur);

  const heatmap = heatmapLayer.generate();

  return heatmap;
}

export function getLockStyle(style: AnnotationStyle): Style {
  return new Style({
    fill: new Fill({ color: style.fillColor }),
    stroke: new Stroke({
      color: alpha(style.strokeColor, 0.8),
      width: style.strokeWidth,
    }),
  });
}

export function createAnnotation(feature: Feature<Polygon>): Annotation {
  const properties = feature.getProperties();
  const { id, dbid } = properties;
  const classId = properties[Params.ClassId];
  const classUuid = properties.class_uuid;

  // TODO: manage properties (setter and getter with types)
  return {
    id,
    uuid: dbid || id,
    ...(classId ? { [Params.ClassId]: classId } : { class_uuid: classUuid }),
    selected: false,
    locked: false,
    syncing: false,
    feature,
    drew: true,
    visible: true,
  };
}

export function isAdaptiveZoomEnabled(annotationsCount = -1) {
  return (
    featCanvasAdaptiveZoom.isEnabled &&
    annotationsCount >= 0 &&
    annotationsCount >= configCanvasAdaptiveZoom.minAnnotations
  );
}

export function getCurrentViewArea(map: Map): number {
  const view = map.getView();
  const mapExtent = view.calculateExtent(map.getSize());

  return view && mapExtent ? getExtentArea(mapExtent) : Infinity;
}

export function isCurrentViewIntersectFeature(map: Map, feature: FeatureLike): boolean {
  const view = map.getView();
  const mapExtent = view.calculateExtent(map.getSize());
  const geometry = feature.getGeometry();
  const featureExtent = geometry?.getExtent();
  const geometryType = geometry?.getType() || 'Point';

  if (!view || !mapExtent || !featureExtent || !['Polygon', 'MultiPolygon'].includes(geometryType)) return false;

  // Convert the map extent to a polygon (rectangle)
  const mapExtentCoords: Position[] = [
    getBottomLeft(mapExtent),
    getBottomRight(mapExtent),
    getTopRight(mapExtent),
    getTopLeft(mapExtent),
    getBottomLeft(mapExtent), // Closing the polygon
  ].map((c: Coordinate) => [c[0], c[1]] as Position);

  const mapExtentPolygon = polygon([mapExtentCoords]);

  // Convert the feature geometry to a linestring (assuming it's a Polygon geometry)

  let featureCoords: Coordinate[];
  const coordinates = (feature as Feature<Polygon | MultiPolygon>).getGeometry()?.getCoordinates();

  if (!coordinates) return false;

  if (geometryType === 'MultiPolygon') {
    featureCoords = (coordinates as Coordinate[][][])[0][0];
  } else {
    featureCoords = (coordinates as Coordinate[][])[0];
  }

  const featureLineString = lineString(featureCoords);

  // Check if the feature intersects the map extent
  return booleanIntersects(mapExtentPolygon, featureLineString);
}

export function featureFilter(f: FeatureLike, map: Map, mapArea: number) {
  // TODO: manage properties (setter and getter with types)

  return (
    f.get('class_uuid') &&
    !f.get('locked') &&
    !f.get('hidden') &&
    f.get('area') &&
    (f.get('area') <= mapArea || isCurrentViewIntersectFeature(map, f))
  );
}

export function getPixelsAround(centerPixel: Pixel, radius: number, numberOfPoints = 36) {
  const pixels = [];
  const [centerX, centerY] = centerPixel;

  // Calculate each point around the circle
  for (let i = 0; i < numberOfPoints; i += 1) {
    const angle = (i * 2 * Math.PI) / numberOfPoints; // Calculate the angle for each point
    const x = centerX + radius * Math.cos(angle); // Calculate the x-coordinate
    const y = centerY + radius * Math.sin(angle); // Calculate the y-coordinate
    pixels.push([x, y]); // Store the pixel coordinate
  }

  return pixels;
}

export function roundCoordinates(geometry: TurfPolygon, precision = 6) {
  geometry.coordinates = geometry.coordinates.map((ring) =>
    ring.map((coord) => coord.map((c) => parseFloat(c.toFixed(precision))))
  );
  return geometry;
}
