import { UserRoleType } from 'src/types/types-user';

import { ConfigFeature } from '../helpers/config-feature';

import { FeatureType } from './enum-features';

/* -------------------------------- Sections; ------------------------------- */

export const featSectionProjectAiActions = new ConfigFeature(
  FeatureType.SectionProjectAiActions,
  {
    enabled: false,
    accessRoles: [UserRoleType.Admin],
    dependencies: [FeatureType.CanvasThreads],
    minVersion: '1.0.0',
  },
  {
    staging: {
      enabled: false,
    },
    production: {
      enabled: false,
      accessRoles: [UserRoleType.User],
    },
  }
);

/* --------------------------------- Projects ------------------------------ */

export const featProjectDuplicateAction = new ConfigFeature(FeatureType.ProjectDuplicateAction, {
  enabled: true,
  accessRoles: [UserRoleType.Admin],
});
export const featProjectAutoAnnotateAction = new ConfigFeature(FeatureType.ProjectDuplicateAction, {
  enabled: true,
  accessRoles: [UserRoleType.Admin],
});
export const featProjectStatsTableExportCSV = new ConfigFeature(FeatureType.ProjectStatsTableExportCSV, {
  enabled: true,
});
