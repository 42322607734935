import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { AuthGuard } from 'src/services/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import { PageProvider } from 'src/contexts/page-context';
import { PATH_AFTER_LOGIN } from 'src/configs/config-global';
import { Params } from 'src/helpers/mapper-route-placeholder';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// FOLDER
const PageFolderList = lazy(() => import('src/pages/dashboard/folder/page-folder-list/list'));
const PageFolderDetails = lazy(() => import('src/pages/dashboard/folder/page-folder-details/details'));
// PROJECT
const ProjectListPage = lazy(() => import('src/pages/dashboard/project/list'));
const ProjectDetailsPage = lazy(() => import('src/pages/dashboard/project/details'));
// AI MODELS
const AiModelNewPage = lazy(() => import('src/pages/dashboard/ai-model/new'));
const AiModelListPage = lazy(() => import('src/pages/dashboard/ai-model/list'));
const AiModelDetailsPage = lazy(() => import('src/pages/dashboard/ai-model/details'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'app',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        // element: <IndexPage />,
        element: <Navigate to={PATH_AFTER_LOGIN} replace />,
      },
      {
        path: 'folders',
        children: [
          {
            index: true,
            element: (
              <PageProvider>
                <PageFolderList />
              </PageProvider>
            ),
          },
          {
            path: `:${Params.FolderId}`,
            element: (
              <PageProvider>
                <PageFolderDetails />
              </PageProvider>
            ),
          },
        ],
      },
      {
        path: 'ai-projects',
        children: [
          {
            index: true,
            element: (
              <PageProvider>
                <ProjectListPage />
              </PageProvider>
            ),
          },
          {
            path: `:${Params.ProjectId}/snapshots/:${Params.SnapshotId}`,
            children: [
              {
                index: true,
                element: (
                  <PageProvider>
                    <ProjectDetailsPage />
                  </PageProvider>
                ),
              },
              {
                path: `ai-models/new`,
                element: (
                  <PageProvider>
                    <AiModelNewPage />
                  </PageProvider>
                ),
              },
            ],
          },
          {
            path: `:${Params.ProjectId}`,
            children: [
              {
                index: true,
                element: (
                  <PageProvider>
                    <ProjectDetailsPage />
                  </PageProvider>
                ),
              },
              {
                path: `ai-models/new`,
                element: (
                  <PageProvider>
                    <AiModelNewPage />
                  </PageProvider>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'ai-models',
        children: [
          {
            index: true,
            element: (
              <PageProvider>
                <AiModelListPage />
              </PageProvider>
            ),
          },
          {
            path: 'new',
            element: (
              <PageProvider>
                <AiModelNewPage />
              </PageProvider>
            ),
          },
          {
            path: `:${Params.AiModelId}`,
            element: (
              <PageProvider>
                <AiModelDetailsPage />
              </PageProvider>
            ),
          },
        ],
      },
    ],
  },
];
