import Map from 'ol/Map';
import { Feature } from 'ol';
import Fill from 'ol/style/Fill';
import { Polygon } from 'ol/geom';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Extent, intersects } from 'ol/extent';

import { Params } from 'src/helpers/mapper-route-placeholder';
import { configCanvasViewer, configCanvasAdaptiveZoom } from 'src/configs/config-canvas';
import { DrawType, Annotation, AnnotationStyle } from 'src/services/canvas/types/types-canvas';

import { TissueType, IAnnotation } from 'src/types/types-tissue';

import MapService from './map-service';
import AnnotationHistory from './annotation-history';
import canvasEmitter from './helpers/canvas-emitter';
import ModeManager from './canvas-tools/mode-manager';
import { ClassId, AnnotationClassProperties } from './types/types';
import AnnotationClassLockManager from './annotation-class-lock-manager';
import AnnotationClassBaseManager from './annotation-class-base-manager';
import AnnotationClassCopyManager from './annotation-class-copy-manager';
import { AnnotationClassSyncManager } from './annotation-class-sync-manager';
import AnnotationClassHeatmapManager from './annotation-class-heatmap-manager';
import AnnotationClassInteractionManager from './annotation-class-interaction-manager';
import AnnotationClassAnnotationsManager from './annotation-class-annotations-manager';
import { getLayerStyle, createCanvasLayer, convertFeatureToGeoJson, convertGeoJsonToFeature } from './helpers/helpers';

// ----------------------------------------------------------------------

/**
 * Class representing an Annotation with associated properties and methods for managing it on a map.
 */
export default class AnnotationClass extends AnnotationClassBaseManager {
  source: VectorSource;

  layer: VectorLayer<Feature>;

  public visible = true;

  private _style: AnnotationStyle;

  public nextAnnotationId: number = 1;

  public locker: AnnotationClassLockManager;

  public synchronizer: AnnotationClassSyncManager;

  public interactor: AnnotationClassInteractionManager;

  public heatmapManager: AnnotationClassHeatmapManager;

  public annotationsManager: AnnotationClassAnnotationsManager;

  public copier: AnnotationClassCopyManager;

  /**
   * Creates an instance of AnnotationClass.
   * @param map - The OpenLayers Map instance.
   * @param id - The ID of the annotation class.
   * @param uuid - The UUID of the annotation class.
   * @param title - The title of the annotation class.
   * @param historyManager - The history manager.
   * @param index - The Z-index of the layer.
   * @param extent - The extent of the layer.
   * @param style - The style of the annotation.
   */
  constructor(
    private map: Map,
    public id: ClassId,
    public uuid: number,
    public title: string,
    public type: TissueType,
    public historyManager: AnnotationHistory,
    index: number,
    extent: Extent | undefined,
    style: Partial<AnnotationStyle>
  ) {
    super();

    this._style = { ...configCanvasViewer.style.layer, ...style };
    const { layer, source } = createCanvasLayer(this._style, index);

    if (extent) layer.setExtent(extent);

    this.map.addLayer(layer);
    this.source = source;
    this.layer = layer;

    this.layer.set('dbid', this.id);
    this.layer.set('uuid', this.uuid);

    this.annotationsManager = new AnnotationClassAnnotationsManager();
    this.locker = new AnnotationClassLockManager(this.annotationsManager, this._style);
    this.synchronizer = new AnnotationClassSyncManager(id, this.locker);
    this.heatmapManager = new AnnotationClassHeatmapManager();
    this.interactor = new AnnotationClassInteractionManager(
      map,
      layer,
      this.annotationsManager,
      this.synchronizer,
      this.historyManager
    );
    this.copier = new AnnotationClassCopyManager(map, layer, this.annotationsManager);

    if (id === undefined) this.syncCreatedClass();

    // Add event listener for changing annotation-class visibility
    layer.on('change:visible', () => {
      const visible = this.layer.getVisible();
      this.visible = visible;
      this.heatmapManager.handleLayerVisibilityChanged(visible);

      canvasEmitter.emit('class-updated');
    });
  }

  /**
   * Sets the id of the annotation class.
   * @param id - The new id to assign from backend.
   */
  set dbid(id: number) {
    this.id = id;
    this.layer.set('dbid', id);
  }

  /**
   * Sets the visibility of the annotation layer.
   * @param visible - True to make visible, false to hide.
   */
  set visibility(visible: boolean) {
    this.layer.setVisible(visible);
  }

  /**
   * Sets the style of the annotation class.
   * @param style - The style to apply.
   */
  set style(style: AnnotationStyle) {
    this._style = { ...this._style, ...style };

    const newStyle = new Style({
      fill: new Fill({
        color: this._style.fillColor,
      }),
      stroke: new Stroke({
        color: this._style.strokeColor,
        width: this._style.strokeWidth,
      }),
    });

    this.layer.setStyle(newStyle);
    this.locker.styles = this._style;
  }

  /**
   * Gets the current style of the annotation class.
   * @returns The current style.
   */
  get style() {
    return this._style;
  }

  /**
   * Gets the color of the annotation class.
   * @returns The stroke color of the annotation.
   */
  get color() {
    return this._style.strokeColor;
  }

  /**
   * Gets the properties of the annotation class.
   * @returns The properties including title and color.
   */
  get properties() {
    return { title: this.title, color: this.color };
  }

  get layerProperties(): AnnotationClassProperties {
    return this.layer.getProperties();
  }

  /**
   * Gets the annotations of the annotation class.
   * @returns The annotations managed by the annotation manager.
   */
  get annotations() {
    return this.annotationsManager.annotations;
  }

  /**
   * Gets the lock status of the annotation class.
   * @returns True if locked, otherwise false.
   */
  get locked() {
    return this.locker.isLocked;
  }

  /**
   * Sets the Z-index of the annotation layer.
   * @param index - The Z-index to set.
   */
  set index(index: number) {
    this.layer.setZIndex(index);
  }

  /**
   * Gets the Z-index of the annotation layer.
   * @returns The current Z-index.
   */
  get index() {
    return this.layer.getZIndex() || configCanvasViewer.style.zIndex;
  }

  /**
   * Synchronizes the creation of the annotation class.
   */
  private async syncCreatedClass() {
    const id = await this.synchronizer.syncCreatedClass(this.properties);
    this.dbid = id;

    this.interactor.setDrawType(DrawType.Polygon);
    ModeManager.getInstance().setMode(DrawType.Polygon);
  }

  /**
   * Synchronizes the updates of the annotation class.
   */
  private async syncUpdatedClass() {
    this.synchronizer.syncUpdatedClass(this.properties);
  }

  /**
   * Updates the properties of the annotation class.
   * @param title - The new title of the annotation class.
   * @param style - The new style of the annotation class.
   */
  updateProperties(title?: string, style?: AnnotationStyle) {
    this.title = title || this.title;
    this.style = style || this._style;

    this.syncUpdatedClass();
  }

  /**
   * Initializes annotations from a list of features.
   * @param features - The features to add as annotations.
   */
  initAnnotations(features: IAnnotation[] = []) {
    const featuresToAdd = features.map(({ id, geoJSON }) => {
      const feature = convertGeoJsonToFeature(geoJSON);

      feature.setProperties({ dbid: id }); // TODO: manage properties (setter and getter with types)

      return feature;
    });

    this.source.addFeatures(featuresToAdd);
  }

  /**
   * Reassigns an annotation from one class to another.
   * @param annotation - The annotation(s) to reassign.
   * @param fromAnnotationClass - The annotation class to reassign from.
   */
  reassignAnnotation(annotation: Annotation | Annotation[], fromAnnotationClass: AnnotationClass) {
    const annotations = Array.isArray(annotation) ? annotation : [annotation];

    // Remove
    fromAnnotationClass.interactor.deselectAllAnnotations();
    annotations.forEach((a) => {
      fromAnnotationClass.annotationsManager.deleteAnnotationByUuid(a.uuid);
    });
    fromAnnotationClass.source.removeFeatures(annotations.map((a) => a.feature));
    MapService.getInstance().updateTotalAnnotationsValueBy = -annotations.length;

    // Add
    this.source.addFeatures(annotations.map((a) => a.feature));

    // Save
    annotations.forEach((annot) => {
      const geo = convertFeatureToGeoJson(annot.feature);
      const payload = {
        ...(this.id ? { [Params.ClassId]: this.id } : { class_index: this.uuid }),
        geoJSON: geo,
        shape_type: geo.geometry.type,
        top_left_coordinate_x: 0,
        top_left_coordinate_y: 0,
      };
      this.synchronizer.syncUpdatedAnnotation(annot.feature.get('dbid'), payload);
    });
  }

  /**
   * Toggles the visibility of a specific annotation.
   * @param annotation - The annotation to toggle.
   * @param value - The desired visibility state.
   */
  toggleAnnotationVisible(annotation: Annotation, value: boolean | null = null) {
    const idx = this.annotationsManager.findAnnotationIndexById(annotation.id);

    if (idx < 0) return;

    const visible = value ?? !annotation.visible;
    this.annotationsManager.updateAnnotationByIndex(idx, { visible });

    if (visible) {
      annotation.feature?.setStyle(undefined);
      annotation.feature.set('hidden', false);
    } else {
      const style = getLayerStyle(configCanvasViewer.style.layerHidden);
      annotation.feature?.setStyle(style);
      annotation.feature.set('hidden', true);
    }

    const deselect = !visible && annotation.selected;

    if (deselect) {
      this.interactor.selectAnnotation(annotation, false, false, false);
    }

    if (visible && !this.visibility) {
      this.visibility = true;
    }

    canvasEmitter.emit('class-updated');
  }

  /**
   * Synchronizes annotations with the current map view.
   * @param mapArea - The area of the map view.
   * @param mapExtent - The extent of the map view.
   * @param maxZoomReached - Whether the maximum zoom level has been reached.
   * @returns An object containing the counts of drawn and removed annotations.
   */
  syncAnnotationWithView(mapArea: number, mapExtent: Extent, maxZoomReached: boolean) {
    let drawCount = 0;
    let removeCount = 0;
    const featuresToDraw: Feature[] = [];
    const featuresToRemove: Feature[] = [];

    this.annotations.forEach((annotation, idx) => {
      const featureArea = annotation.feature.get('area');
      const isVisible =
        featureArea < mapArea && featureArea >= configCanvasAdaptiveZoom.minAnnotationAreaToRender * mapArea;
      let shouldDraw = isVisible;
      const featureExtent = annotation.feature.getGeometry()?.getExtent();
      const isInViewport = intersects(mapExtent, featureExtent ?? []);
      const alreadyDraw = this.annotations[idx].drew;
      shouldDraw = (isVisible && isInViewport) || maxZoomReached;
      const shouldRemove = !shouldDraw;

      if (shouldDraw && !alreadyDraw) {
        drawCount += 1;
        this.annotations[idx].drew = true;
        featuresToDraw.push(annotation.feature);
      } else if (shouldRemove && alreadyDraw) {
        removeCount += 1;
        this.annotations[idx].drew = false;
        featuresToRemove.push(annotation.feature);
      }
    });

    this.source.addFeatures(featuresToDraw);

    this.source.removeFeatures(featuresToRemove);

    return { drawCount, removeCount };
  }

  /**
   * Disposes the annotation class, removing it from the map and disabling interactions.
   */
  dispose(): void {
    this.interactor.deselectAllAnnotations();
    this.layer.dispose();
    this.interactor.disableAllInteractions();
    this.copier.removeListeners();
  }

  getAnnotationsIntersectingPolygon(polygon: Polygon) {
    const annotations: any = [];

    this.source.forEachFeatureIntersectingExtent(polygon.getExtent(), (feature) => {
      const geometry = feature.getGeometry();

      if (geometry && !feature.get('brush') && polygon.intersectsExtent(geometry.getExtent())) {
        annotations.push(this.annotationsManager.findAnnotationByFeatureId(feature.get('id')));
      }
    });

    return annotations;
  }
}
