import Map from 'ol/Map';
import Feature from 'ol/Feature';
import hotkeys from 'hotkeys-js';
import { debounce } from 'lodash';
import { getArea } from 'ol/sphere';
import BaseEvent from 'ol/events/Event';
import Geometry from 'ol/geom/Geometry';
import VectorLayer from 'ol/layer/Vector';
import { fromCircle } from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { Point, Circle, Polygon } from 'ol/geom';
import { ModifyEvent } from 'ol/interaction/Modify';
import { TranslateEvent } from 'ol/interaction/Translate';
import { never, primaryAction } from 'ol/events/condition';
import Collection, { CollectionEvent } from 'ol/Collection';
import VectorSource, { VectorSourceEvent } from 'ol/source/Vector';
import { DrawEvent, createBox, createRegularPolygon } from 'ol/interaction/Draw';
import { Draw, Snap, Modify, Select, DragBox, DragPan, Translate } from 'ol/interaction';

import { store } from 'src/store';
import { LocalStorage } from 'src/helpers/local-storage';
import { EventHub, EventTypes } from 'src/services/event-hub';
import { Params } from 'src/helpers/mapper-route-placeholder';
import { StorageKeysType } from 'src/configs/types/enum-storage-keys';
import ModeManager from 'src/services/canvas/canvas-tools/mode-manager';
import { addSelectedAnnotationsCount } from 'src/store/slices/slice-canvas';
import { Brush, DrawType, Annotation, SelectType } from 'src/services/canvas/types/types-canvas';
import { AmplitudeEvents, AmplitudeService, AmplitudeEventPayloads } from 'src/services/amplitude';
import { configCanvasTools, configCanvasViewer, configCanvasAdaptiveZoom } from 'src/configs/config-canvas';

import MapService from './map-service';
import AnnotationHistory from './annotation-history';
import canvasEmitter from './helpers/canvas-emitter';
import AnnotationClassBaseManager from './annotation-class-base-manager';
import { AnnotationClassSyncManager } from './annotation-class-sync-manager';
import AnnotationClassAnnotationsManager from './annotation-class-annotations-manager';
import { getLayerStyle, createAnnotation, convertFeatureToGeoJson } from './helpers/helpers';

// ----------------------------------------------------------------------

/**
 * Manages interactions on the map, including drawing, modifying, and selecting annotations.
 */
export default class AnnotationClassInteractionManager extends AnnotationClassBaseManager {
  private drawInteraction: Draw;

  private modifyInteraction: Modify;

  private selectInteraction: Select;

  private dragPan: DragPan;

  private translateInteraction: Translate | undefined = undefined;

  private dragBox: DragBox;

  private polygonBox: Draw;

  private source: VectorSource<Feature<Geometry>>;

  private recentlyCreatedAnnotation: Annotation | null = null;

  private recentlyCreatedAnnotationTimeout: any;

  private drawMode: DrawType | null = null;

  private modifyingEnabled = false;

  private translateEnabled = false;

  private selectingEnabled = false;

  private dragEnabled = true;

  public originalGeometries: (Geometry | undefined)[] = [];

  private debouncedAddEditInteractions: Function;

  // ----------------------------------------------------------------------

  /**
   * @param map The OpenLayers map instance.
   * @param layer The vector layer associated with the interactions.
   * @param annotationsManager Manages the annotations for the interactions.
   * @param syncManager Manages synchronization with a backend or state.
   */
  constructor(
    private map: Map,
    private layer: VectorLayer<Feature<Geometry>>,
    private annotationsManager: AnnotationClassAnnotationsManager,
    private syncManager: AnnotationClassSyncManager,
    private historyManager: AnnotationHistory
  ) {
    super();
    const source = layer.getSource() as VectorSource;
    this.source = source;

    this.drawInteraction = new Draw({ source, type: DrawType.Polygon });
    this.modifyInteraction = new Modify({ source });

    this.selectInteraction = new Select({ condition: never });
    this.dragBox = new DragBox({ condition: primaryAction });
    this.polygonBox = new Draw({
      source: new VectorSource(),
      type: 'Polygon',
      freehand: true,
      style: configCanvasTools.select.polygon.style,
    });

    const snap = new Snap({ source });
    map.addInteraction(snap);

    this.dragPan = new DragPan();

    this.addListeners();

    this.debouncedAddEditInteractions = debounce(this.addEditInteractions.bind(this), 300);
  }

  // ----------------------------------------------------------------------

  get classId() {
    return this.layer.get('dbid');
  }

  get classUuid() {
    return this.layer.get('uuid');
  }

  get selectedFeatures() {
    return this.selectInteraction.getFeatures();
  }

  // ----------------------------------------------------------------------

  /**
   * Enables modifying interactions on the map.
   */
  enableModifying() {
    this.disableAllInteractions();
    this.map.addInteraction(this.modifyInteraction);
    this.modifyingEnabled = true;
  }

  /**
   * Disables modifying interactions on the map.
   */
  disableModifying() {
    this.map.removeInteraction(this.modifyInteraction);
    this.modifyingEnabled = false;
  }

  /**
   * Enables translation interactions on the map.
   */
  enableTranslate() {
    this.translateEnabled = true;
  }

  /**
   * Disables translation interactions on the map.
   */
  disableTranslate() {
    this.translateEnabled = false;
  }

  /**
   * Enables selecting interactions on the map.
   * @param type The selection type (Single, Box, or Polygon).
   * @param skipDisableDraw Whether to skip disabling drawing interactions.
   */
  enableSelecting(type: SelectType, skipDisableDraw = false) {
    if (!skipDisableDraw) {
      this.setDrawType(null);
    }

    this.selectingEnabled = true;
    this.disableModifying();
    this.disableTranslate();
    this.map.addInteraction(this.selectInteraction);

    if (type === SelectType.Single) {
      this.map.removeInteraction(this.dragBox);
      this.map.removeInteraction(this.polygonBox);
    } else if (type === SelectType.Box) {
      this.map.addInteraction(this.dragBox);
      this.map.removeInteraction(this.polygonBox);
    } else if (type === SelectType.Polygon) {
      this.map.addInteraction(this.polygonBox);
      this.map.removeInteraction(this.dragBox);
    }
  }

  /**
   * Disables selecting interactions on the map.
   */
  disableSelecting() {
    this.selectingEnabled = false;
    this.map.removeInteraction(this.selectInteraction);
    this.map.removeInteraction(this.dragBox);
    this.map.removeInteraction(this.polygonBox);
  }

  /**
   * Sets the drawing type for the interactions.
   * @param type The type of drawing (e.g., Polygon, Circle).
   * @param freehand Whether to allow freehand drawing.
   */
  setDrawType(type: DrawType | null, freehand = true) {
    if (type !== null) {
      this.disableAllInteractions();
    }

    this.map.removeInteraction(this.drawInteraction);

    if (this.drawMode === type || type === null) {
      this.drawMode = null;
      return;
    }

    this.selectedFeatures.clear();
    this.drawMode = type;

    if (type === DrawType.Rectangle) {
      this.drawInteraction = new Draw({
        source: this.source,
        type: DrawType.Circle,
        geometryFunction: createBox(),
        freehand,
        style(feature) {
          return [configCanvasTools.draw.polygon.style, configCanvasTools.draw.innerPolygon.style];
        },
      });
    } else if (type === DrawType.Circle) {
      this.drawInteraction = new Draw({
        source: this.source,
        type,
        freehand,
        geometryFunction: createRegularPolygon(),
      });
    } else {
      this.drawInteraction = new Draw({
        source: this.source,
        type,
        freehand,
        style(feature) {
          return [configCanvasTools.draw.polygon.style, configCanvasTools.draw.innerPolygon.style];
        },
      });
    }

    this.map.addInteraction(this.drawInteraction);
  }

  /**
   * Disables all interactions on the map.
   */
  disableAllInteractions() {
    this.disableModifying();
    this.disableTranslate();
    this.disableSelecting();
    this.setDrawType(null);
  }

  /**
   * Gets the current draw type.
   * @returns The current draw type.
   */
  public getDrawType() {
    return this.drawMode;
  }

  /**
   * Checks if modifying is enabled.
   * @returns Whether modifying is enabled.
   */
  public isModifyingEnabled() {
    return this.modifyingEnabled;
  }

  /**
   * Checks if translation is enabled.
   * @returns Whether translation is enabled.
   */
  public isTranslateEnabled() {
    return this.translateEnabled;
  }

  /**
   * Checks if selecting is enabled.
   * @returns Whether selecting is enabled.
   */
  public isSelectingEnabled() {
    return this.selectingEnabled;
  }

  // ----------------------------------------------------------------------

  /**
   * Handles delete annotation or list of annotations
   */
  deleteAnnotation(annotation: Annotation | Annotation[]) {
    const annotations = Array.isArray(annotation) ? annotation : [annotation];
    const annotationIds = annotations.map((a) => a.id);
    const annotationFeatures = annotations.map((a) => a.feature);

    const ids = annotations.filter((i) => !i.feature.get('performance')).map((i) => i.feature.get('dbid'));

    if (ids.length > 0) {
      this.syncManager.syncDeletedAnnotations(ids); // Save
    }

    annotations
      .filter((i) => !i.feature.get('performance'))
      .forEach((a) => {
        this.selectedFeatures.remove(a.feature);
        this.historyManager.recordAction({
          action: 'delete',
          annotation: a,
          before: a.feature.getGeometry()?.clone(),
        });
      });

    // Remove
    this.source.removeFeatures(annotationFeatures);
    annotationIds.forEach((id) => id && this.annotationsManager.deleteAnnotationById(id));

    // Update the total number of annotations
    MapService.getInstance().updateTotalAnnotationsValueBy = -annotationIds.length;
  }

  async bulkDeleteAllAnnotations(): Promise<void> {
    const annotationDbids = this.annotationsManager.annotations.map((i) => i.feature.get('dbid'));

    // Save
    await this.syncManager.syncDeletedAnnotations(annotationDbids);

    // Remove
    this.source.clear();
    this.annotationsManager.bulkDeleteAllAnnotations();

    // Update the total number of annotations
    MapService.getInstance().updateTotalAnnotationsValueBy = -annotationDbids.length;
  }

  /**
   * Handles the delete action when the delete key is pressed.
   */
  public handleDelClicked() {
    // TODO: add a function in the AnnotationManager to get areThereAnySelected
    const areThereAnySelected = this.annotationsManager.annotations.some((i) => i.selected && !i.locked);

    if (areThereAnySelected) {
      this.deleteSelectedFeatures();
    } else if (this.recentlyCreatedAnnotation && !this.recentlyCreatedAnnotation.locked) {
      this.deleteAnnotation(this.recentlyCreatedAnnotation);
    }
  }

  /**
   * Deletes selected features from the map and synchronizes the deletion.
   */
  public deleteSelectedFeatures(): void {
    const selectedFeatures = this.selectInteraction.getFeatures();

    const annotations = selectedFeatures.getArray().map((feature) => {
      const idx = this.annotationsManager.findAnnotationIndexByFeatureId(feature.get('id'));

      if (idx === -1) return undefined;

      return this.annotationsManager.annotations[idx];
    });

    this.deleteAnnotation(annotations.filter((a) => !!a) as Annotation[]);
  }

  /**
   * Selects an annotation and updates its state.
   * @param annotation The annotation to select.
   * @param unselectOther Whether to unselect other annotations.
   * @param disableFit Whether to disable fitting the selected annotation.
   * @param disableDeselect Whether to disable deselecting the annotation.
   */
  public selectAnnotation(annotation: Annotation, unselectOther = false, disableFit = false, disableDeselect = false) {
    const idx = this.annotationsManager.findAnnotationIndexById(annotation.id);
    if (idx < 0 || !annotation.id) return;

    if (disableDeselect || !annotation.selected) {
      if (!disableFit) {
        const geometry = annotation.feature.getGeometry(); // Get the feature's geometry
        const extent = geometry?.getExtent(); // Get the extent of the geometry

        if (extent) {
          this.map.getView().fit(extent, {
            duration: 1000,
            minResolution: configCanvasAdaptiveZoom.minResolutionToLocateAnnotation,
            padding: [50, 50, 50, 50],
          });
        }
      }

      if (unselectOther) {
        // TODO: find a better way to deselect all annotations
        MapService.getInstance().annotationClasses.forEach((a) => a.interactor.deselectAllAnnotations());
      }
    }

    if (!annotation.selected || unselectOther) {
      this.enableSelecting(SelectType.Single, true);
      this.selectedFeatures.push(annotation.feature);
      this.annotationsManager.updateAnnotationByIndex(idx, { selected: true });

      EventHub.emit(EventTypes.INTERACTION_SELECT_ANNOTATION, {
        tissueId: this.classId,
        annotationId: annotation.id.toString(),
        annotationIdx: idx,
      });
    } else if (!disableDeselect) {
      this.selectedFeatures.remove(annotation.feature);
      this.annotationsManager.updateAnnotationByIndex(idx, { selected: false });
    }

    canvasEmitter.emit('class-updated');
  }

  /**
   * select all annotations
   */
  selectAllAnnotations() {
    if (this.annotationsManager.annotations.some((a) => a.selected)) return;

    if (!this.selectingEnabled) {
      MapService.getInstance().annotationClasses.forEach((cls) => cls?.interactor.enableSelecting(SelectType.Single));
      EventHub.emit(EventTypes.TOOLBAR_SELECT_TOOL, { tool: SelectType.Single });
    }

    this.selectedFeatures.clear();
    this.selectedFeatures.extend(this.annotationsManager.annotations.map((a) => a.feature));
    this.annotationsManager.bulkUpdateAllAnnotations({ selected: true });
  }

  /**
   * Deselect all annotations
   */
  deselectAllAnnotations() {
    this.selectedFeatures.clear();

    // TODO: find a better way to keep hidden annotation hidden :)
    this.annotationsManager.getHiddenAnnotations().forEach((a) => {
      const style = getLayerStyle(configCanvasViewer.style.layerHidden);
      a.feature?.setStyle(style);
    });
  }

  /**
   * Handles the escape key action, resetting the drawing mode.
   */
  handleEscClicked() {
    this.setDrawType(null);

    const currentMode = ModeManager.getInstance().getMode() as SelectType;

    this.deselectAllAnnotations();

    if (!Object.values(SelectType).includes(currentMode)) {
      this.enableSelecting(SelectType.Single);

      EventHub.emit(EventTypes.TOOLBAR_SELECT_TOOL, { tool: SelectType.Single });
    }
  }

  // ----------------------------------------------------------------------

  private addListeners() {
    this.source.on('addfeature', this.handleAddFeature.bind(this));
    this.modifyInteraction.on('modifystart', this.handleModifyStart.bind(this));
    this.modifyInteraction.on('modifyend', this.handleModifyEnd.bind(this));

    this.selectedFeatures.on(['add', 'remove'], (evt: BaseEvent | Event) => {
      this.handleSelectInteraction(evt as CollectionEvent<Feature>);
      this.debouncedAddEditInteractions();
    });
    this.dragBox.on('boxend', this.handleDragBoxEnd.bind(this));
    this.polygonBox.on('drawend', AnnotationClassInteractionManager.handlePolygonBoxDrawEnd.bind(this));
  }

  private async handleAddFeature(event: VectorSourceEvent) {
    const currentMode = ModeManager.getInstance().getMode();
    const feature = event.feature as Feature<Polygon>;
    if (!feature) return;
    if (feature.get('brush')) return;

    if (!feature.get('dbid') && feature.getGeometry() instanceof Point) {
      const geo = (feature as unknown as Feature<Point>).getGeometry() as Point;
      const coordinate = geo.getCoordinates();
      const newFeature = new Feature(fromCircle(new Circle(coordinate, 10), 16));
      this.source.removeFeature(feature);
      this.source.addFeature(newFeature);
      return;
    }

    if (feature.get('id') && this.annotationsManager.findAnnotationIndexByFeatureId(feature.get('id')) >= 0) {
      return;
    }

    if (!this.layer.getVisible()) this.layer.setVisible(true);

    const dbid = feature.get('dbid');
    const id = (feature as any).ol_uid;

    const geometry = feature.getGeometry();
    const area = geometry ? getArea(geometry) : 0;

    // TODO: manage properties (setter and getter with types)
    feature.setProperties({
      id,
      dbid,
      area,
      uuid: dbid,
      [Params.ClassId]: this.classId,
      class_uuid: this.classUuid,
    });

    let createdAnnotation: Annotation = createAnnotation(feature);
    const annotationIdx = this.annotationsManager.addAnnotation(createdAnnotation);
    MapService.getInstance().updateTotalAnnotationsValueBy = 1;

    if (!feature.get('force') && currentMode === Brush.Brush) {
      this.source.removeFeature(feature);
      return;
    }

    if (dbid || feature.get('performance')) return;

    // send to server
    const geo = createdAnnotation.geoJSON ?? convertFeatureToGeoJson(feature);

    if (geo.geometry.type === 'Polygon' && geo.geometry.coordinates[0].length < 3) {
      this.source.removeFeature(feature);
      console.warn('polygon must have at least 3 points');
      return;
    }

    const payload = {
      ...(this.classId ? { [Params.ClassId]: this.classId } : { class_index: this.classUuid }),
      geoJSON: geo,
      shape_type: geo.geometry.type,
      top_left_coordinate_x: 0,
      top_left_coordinate_y: 0,
    };

    this.historyManager.recordAction({
      action: 'create',
      annotation: createdAnnotation,
      after: feature.getGeometry()?.clone(),
    });

    AmplitudeService.track(AmplitudeEvents.CanvasAnnotationDrawn, {
      'zoom-level': this.map?.getView()?.getZoom() || 0,
      'selected-tool': currentMode as AmplitudeEventPayloads[AmplitudeEvents.CanvasAnnotationDrawn]['selected-tool'],
    });

    const annotationId = await this.syncManager.syncCreatedAnnotation(annotationIdx, payload);

    const annotation = this.annotationsManager.annotations[annotationIdx];

    this.annotationsManager.updateAnnotationByIndex(annotationIdx, { uuid: annotationId });
    createdAnnotation = { ...createdAnnotation, uuid: annotationId };
    annotation.feature?.set('dbid', annotationId);
    annotation.feature?.set('uuid', annotationId);

    const newCurrentMode = ModeManager.getInstance().getMode();
    const isLastCreatedItem = annotationIdx === this.annotationsManager.length - 1;
    const contextMenuHidden = LocalStorage.getItem(StorageKeysType.CanvasViewerContextMenuVisible) ?? true;
    const isCurrentClassActive = this.classId === MapService.getInstance().getActiveAnnotationClass()?.id;

    if (contextMenuHidden && isLastCreatedItem && isCurrentClassActive && currentMode === newCurrentMode) {
      this.selectAnnotation(createdAnnotation, true, true);
    }
  }

  protected handleTranslateStart(event: TranslateEvent) {
    const features = event?.features?.getArray();

    if (!features.length) return;

    this.originalGeometries = features.map((feat) => feat.getGeometry()?.clone());
  }

  protected handleTranslateEnd(event: TranslateEvent) {
    const features = event?.features?.getArray();

    if (!features.length) return;

    // eslint-disable-next-line array-callback-return
    features.map((feature, idx) => {
      const classUuid = feature.get('class_uuid'); // TODO: manage properties (setter and getter with types)
      const notBelongsToCurrentClass = classUuid && feature.get('class_uuid') !== this.classUuid; // TODO: manage properties (setter and getter with types)

      if (notBelongsToCurrentClass) {
        console.error('translate end notBelongsToCurrentClass ??');
        return;
      }

      const annotation = this.annotationsManager.findAnnotationByFeatureId(feature.get('id'));

      if (!annotation || annotation.feature?.get('performance')) return;

      if (annotation && !annotation?.selected) {
        this.selectAnnotation(annotation, true, true);
      }

      const geo = convertFeatureToGeoJson(feature);
      const payload = {
        ...(this.classId ? { [Params.ClassId]: this.classId } : { class_index: this.classUuid }),
        geoJSON: geo,
        shape_type: geo.geometry.type,
        top_left_coordinate_x: 0,
        top_left_coordinate_y: 0,
      };

      this.syncManager.syncUpdatedAnnotation(feature.get('dbid'), payload);
      AmplitudeService.track(AmplitudeEvents.CanvasAnnotationModified, {
        'zoom-level': this.map?.getView()?.getZoom() || 0,
        'selected-tool': SelectType.Translate,
      });

      if (this.originalGeometries[idx]) {
        this.historyManager.recordAction({
          action: 'update',
          annotation,
          before: this.originalGeometries[idx]?.clone(),
          after: annotation?.feature.getGeometry()?.clone(),
        });
      }
    });
  }

  private handleModifyStart(event: ModifyEvent) {
    const features = event.features.getArray();

    if (!features.length) return;

    this.originalGeometries = (features || []).map((f) => f.getGeometry()?.clone());
  }

  private handleModifyEnd(event: ModifyEvent) {
    const features = event.features.getArray();
    features
      .filter((f) => !f.get('performance'))
      .forEach((feature, idx) => {
        this.updateAnnotationByFeature(feature, this.originalGeometries.at(idx));
        AmplitudeService.track(AmplitudeEvents.CanvasAnnotationModified, {
          'zoom-level': this.map?.getView()?.getZoom() || 0,
          'selected-tool': 'point',
        });
      });
  }

  updateAnnotationByFeature(feature: Feature<Geometry>, originalGeometry: Geometry | undefined = undefined) {
    const geo = convertFeatureToGeoJson(feature);
    const payload = {
      ...(this.classId ? { [Params.ClassId]: this.classId } : { class_index: this.classUuid }),
      geoJSON: geo,
      shape_type: geo.geometry.type,
      top_left_coordinate_x: 0,
      top_left_coordinate_y: 0,
    };

    const annotation = this.annotationsManager.findAnnotationByFeatureId(feature.get('id'));

    if (!annotation) return;

    const geometry = feature.getGeometry();
    const area = geometry ? getArea(geometry) : 0;
    feature.set('area', area);
    annotation?.feature.set('area', area);

    this.syncManager.syncUpdatedAnnotation(feature.get('dbid'), payload);

    this.historyManager.recordAction({
      action: 'update',
      annotation,
      before: originalGeometry?.clone(),
      after: annotation?.feature.getGeometry()?.clone(),
    });
  }

  private addEditInteractions() {
    const currentMode = ModeManager.getInstance().getMode();
    this.map.removeInteraction(this.modifyInteraction);
    this.modifyInteraction.dispose();

    if (this.translateInteraction) {
      this.map.removeInteraction(this.translateInteraction);
      this.translateInteraction?.dispose();
    }

    const features = this.selectInteraction.getFeatures();

    if (features.getLength() === 0) {
      return;
    }

    const collection = new Collection(features.getArray());

    if (currentMode === SelectType.Translate) {
      this.translateInteraction = new Translate({
        features: collection,
      });
      this.map.addInteraction(this.translateInteraction);
      this.translateInteraction.on('translatestart', this.handleTranslateStart.bind(this));
      this.translateInteraction.on('translateend', this.handleTranslateEnd.bind(this));
    } else {
      this.modifyInteraction = new Modify({
        features: collection,
      });

      this.map.addInteraction(this.modifyInteraction);

      this.modifyInteraction.on('modifystart', this.handleModifyStart.bind(this));
      this.modifyInteraction.on('modifyend', this.handleModifyEnd.bind(this));
    }
  }

  private handleSelectInteraction(event: CollectionEvent<Feature>) {
    const { element, type } = event as CollectionEvent<Feature>;

    if (!element || !['add', 'remove'].includes(type)) {
      return;
    }

    const isSelected = type === 'add';
    const idx = this.annotationsManager.findAnnotationIndexByFeatureId(element.get('id'));

    if (idx < 0) return;

    if (type === 'add') {
      MapService.getInstance().setActiveAnnotationClass(this.classUuid, true);
    }

    this.annotationsManager.updateAnnotationByIndex(idx, { selected: isSelected });

    store.dispatch(addSelectedAnnotationsCount(isSelected ? 1 : -1));
  }

  private static handlePolygonBoxDrawEnd(event: DrawEvent) {
    const polygon = event.feature.getGeometry();

    AnnotationClassInteractionManager.handleDrawEndSelectAnnotations(polygon);
  }

  private handleDragBoxEnd() {
    const box = this.dragBox.getGeometry();

    AnnotationClassInteractionManager.handleDrawEndSelectAnnotations(box);
  }

  private static handleDrawEndSelectAnnotations(polygon: Geometry | undefined) {
    if (!polygon) return;

    const { annotationClasses } = MapService.getInstance();

    const multi = hotkeys.isPressed('command') || hotkeys.isPressed('ctrl') || hotkeys.isPressed('shift');

    annotationClasses.forEach((annotationClass) => {
      const selectedFeatures = annotationClass.source.getFeatures().filter((feature) => {
        const geometry = feature.getGeometry();

        if (geometry && !feature.get('locked') && !feature.get('hidden')) {
          if (geometry instanceof Polygon) {
            return geometry.getCoordinates().some((c1) => c1.some((c2) => polygon.intersectsCoordinate(c2)));
          }

          if (geometry instanceof MultiPolygon) {
            return geometry
              .getCoordinates()
              .some((c1) => c1.some((c2) => c2.some((c3) => polygon.intersectsCoordinate(c3))));
          }

          return polygon.intersectsExtent(geometry.getExtent());
        }
        return false;
      });

      if (!multi) {
        annotationClass.interactor.selectedFeatures.clear();
      }

      selectedFeatures.forEach((feature) => {
        if (!annotationClass.interactor.isSelectingEnabled()) {
          annotationClass.interactor.enableSelecting(SelectType.Single);
        }
        annotationClass.interactor.selectedFeatures.push(feature);
      });
    });
  }
}
