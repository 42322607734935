import { DeviceType } from 'src/utils/user-agent';

import { Brush, DrawType, SelectType, ThreadCommentType } from 'src/services/canvas/types/types-canvas';

import { UserRoleType } from 'src/types/types-user';

import { ConfigFeature } from '../helpers/config-feature';

import { FeatureType } from './enum-features';

/* -------------------------------- Canvas V4 ------------------------------- */

export const featCanvasV4 = new ConfigFeature(FeatureType.CanvasV4, {
  enabled: true,
  accessRoles: [UserRoleType.Admin],
});

/* --------------------------------- Canvas; -------------------------------- */

export const featCanvasRoiStatsV2 = new ConfigFeature(FeatureType.CanvasRoiStatsV2, {
  enabled: true,
  accessRoles: [UserRoleType.Admin],
});

export const featCanvasRabbitMenu = new ConfigFeature(FeatureType.CanvasRabbitMenu, {
  enabled: true,
});

export const featCanvasContextMenu = new ConfigFeature(FeatureType.CanvasContextMenu, {
  enabled: true,
});

export const featCanvasUndoRedo = new ConfigFeature(FeatureType.CanvasUndoRedo, {
  enabled: true,
});

export const featCanvasThreads = new ConfigFeature(FeatureType.CanvasThreads, {
  enabled: true,
});

export const featCanvasHeatmap = new ConfigFeature(FeatureType.CanvasHeatmap, {
  enabled: true,
});

export const featCanvasRoiStats = new ConfigFeature(FeatureType.CanvasRoiStats, {
  enabled: true,
});

export const featCanvasCopyPaste = new ConfigFeature(FeatureType.CanvasCopyPaste, {
  enabled: true,
});

export const featCanvasLocateAnnotation = new ConfigFeature(FeatureType.CanvasLocateAnnotation, {
  enabled: false,
});

export const featCanvasAdaptiveZoom = new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
  enabled: {
    [DeviceType.Mobile]: false,
    [DeviceType.Tablet]: false,
    [DeviceType.Desktop]: false,
  },
});

export const featCanvasMovementControl = new ConfigFeature(FeatureType.CanvasMovementStick, {
  enabled: {
    [DeviceType.Mobile]: true,
    [DeviceType.Tablet]: true,
    [DeviceType.Desktop]: false,
  },
});

export const featCanvasOverviewMap = new ConfigFeature(FeatureType.CanvasOverviewMap, {
  enabled: true,
});

export const featCanvasQuickActionsMenu = new ConfigFeature(
  FeatureType.CanvasQuickActionsMenu,
  { enabled: true },
  {
    staging: { enabled: false },
    production: { enabled: false },
  }
);

// TODO: update the string enum for each feature
export const featCanvasTools = {
  autoAnnotate: new ConfigFeature(FeatureType.CanvasToolbarAutoAnnotate, {
    enabled: true,
    accessRoles: [UserRoleType.Admin],
  }),
  draw: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  brush: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  do: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: featCanvasUndoRedo.isEnabled,
  }),
  undo: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: featCanvasUndoRedo.isEnabled,
  }),
  divider: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [SelectType.Edit]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [SelectType.Translate]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [DrawType.Point]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [DrawType.Circle]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [DrawType.Rectangle]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [Brush.Brush]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [Brush.BrushDraw]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [DrawType.Polygon]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [SelectType.Box]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: false,
  }),
  [SelectType.Single]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [SelectType.Polygon]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [ThreadCommentType.Comment]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: featCanvasThreads.isEnabled,
  }),
};
