import { ConfigBase } from './helpers/config-base';

/* ----------------------------------- AWS ---------------------------------- */

export const AWS_REGION = import.meta.env.VITE_AWS_REGION;
export const AWS_ACCESS_KEY_ID = import.meta.env.VITE_AWS_ACCESS_KEY_ID;
export const AWS_SECRET_ACCESS_KEY = import.meta.env.VITE_AWS_SECRET_ACCESS_KEY;

export const AWS_S3_BUCKET = import.meta.env.VITE_AWS_S3_BUCKET;

export const AWS_AMPLIFY_REGION = import.meta.env.VITE_AWS_AMPLIFY_REGION;
export const AWS_AMPLIFY_USER_POOL_ID = import.meta.env.VITE_AWS_AMPLIFY_USER_POOL_ID;
export const AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID = import.meta.env.VITE_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID;

/* ----------------------------------- AMPLITUDE --------------------------------- */

export const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY;

/* ----------------------------------- SENTRY --------------------------------- */

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_ORG = import.meta.env.VITE_SENTRY_ORG;
export const SENTRY_PROJECT = import.meta.env.VITE_SENTRY_PROJECT;
export const configSentry = new ConfigBase(
  'sentry-service',
  { isEnabled: false, debug: false, withProfiler: false },
  {
    staging: { isEnabled: true, debug: false, withProfiler: true },
    production: { isEnabled: true, debug: false, withProfiler: true },
  }
).getSetup();
