import { useMemo, useCallback } from 'react';

import { paths } from '../paths';
import { PathItem, PathObject } from '../types';

import { useParams } from './use-params';
import { usePathname } from './use-pathname';

// ----------------------------------------------------------------------

export function useActivePath(): PathItem {
  const dynamicParams = Object.values(useParams() as Record<string, string>);

  const pathname = usePathname().replace(/\/+$/, ''); // remove trailing slash from pathname if it exists

  const findPathObject = useCallback((obj: PathObject, currentPath: string, params: string[]): PathItem | undefined => {
    const entries = Object.entries(obj); // Convert Object.entries(obj) to an array and iterate over it

    for (let i = 0; i < entries.length; i += 1) {
      const entry = entries[i];
      const value = entry[1]; // Directly using index to avoid declaring unused variable

      if (isPathItem(value)) {
        const pathValue = typeof value.to === 'function' ? value.to(...params) : value.to;
        if (pathValue === currentPath) {
          return { id: value.id, to: pathValue };
        }
      } else {
        const result = findPathObject(value, currentPath, params); // Recursive search in nested objects
        if (result) {
          return result;
        }
      }
    }

    return undefined; // Explicitly return undefined to satisfy consistent-return rule
  }, []);

  const pathItem = useMemo(() => {
    const result = findPathObject({ routes: { ...paths.dashboard, ...paths.canvas } }, pathname, dynamicParams);
    if (result) return result;

    // Given the assurance, this line should theoretically never be reached.
    throw new Error(`Could not find path ID for this path: ${pathname}`);
  }, [dynamicParams, findPathObject, pathname]);

  return pathItem;
}

// ----------------------------------------------------------------------

// Helper function to check if an object is a PathItem
const isPathItem = (obj: any): obj is PathItem => obj && typeof obj === 'object' && 'id' in obj && 'to' in obj;
