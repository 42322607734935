import { useSWRConfig } from 'swr';
import { useMemo, useEffect, useReducer, useCallback } from 'react';

import { IHttpClient } from 'src/services/http';
import { useAppSelector } from 'src/store/hooks';
import { TYPES } from 'src/services/enum-services';
import { container } from 'src/services/container';
import { StorageService } from 'src/services/storage';
import { LocalStorage } from 'src/helpers/local-storage';
import { AmplitudeService } from 'src/services/amplitude/';
import { GetMeRes } from 'src/services/api/types/types-req-res';
import { setSentryUser, resetSentryUser } from 'src/services/sentry';
import { StorageKeysType } from 'src/configs/types/enum-storage-keys';

import { IAuthService } from '../auth.interface';
import { SignUpInput, ActionMapType, AuthStateType, AuthMethodType } from '../types';

import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

const httpClient = container.get<IHttpClient>(TYPES.HttpClient);
const authService = container.get<IAuthService>(TYPES.AuthService);

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthStateType['user'];
    allowedPages: AuthStateType['allowedPages'];
    navigationPages: AuthStateType['navigationPages'];
  };
  [Types.LOGIN]: {
    user: AuthStateType['user'];
    allowedPages: AuthStateType['allowedPages'];
    navigationPages: AuthStateType['navigationPages'];
  };
  [Types.LOGOUT]: undefined;
};

type Action = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
  allowedPages: [],
  navigationPages: [],
};

const reducer = (state: AuthStateType, action: Action) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
      allowedPages: action.payload.allowedPages,
      navigationPages: action.payload.navigationPages,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
      allowedPages: action.payload.allowedPages,
      navigationPages: action.payload.navigationPages,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
      allowedPages: [],
      navigationPages: [],
    };
  }
  return state;
};

// ----------------------------------------------------------------------

interface Props {
  children: React.ReactNode;
}

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { meAction } = useAppSelector(({ app }) => app);

  const { mutate } = useSWRConfig();

  const initialize = useCallback(async () => {
    try {
      const { userId: currentUser } = await authService.getCurrentUser();

      const userAttributes = await authService.fetchUserAttributes();

      const { idToken, accessToken } = (await authService.fetchAuthSession()).tokens ?? {};

      if (currentUser && meAction) {
        const { allowed_pages, user, navigation_pages } = await httpClient.request<GetMeRes>(meAction);

        setSentryUser(user);
        AmplitudeService.setUser(user);
        LocalStorage.setItem(StorageKeysType.User, user);

        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              ...user,
              ...userAttributes,
              idToken,
              accessToken,
              id: userAttributes.sub,
              displayName: `${user.first_name} ${user.last_name}`,
            },
            allowedPages: allowed_pages,
            navigationPages: navigation_pages,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
            allowedPages: [],
            navigationPages: [],
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
          allowedPages: [],
          navigationPages: [],
        },
      });
    }
  }, [meAction]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (email: string, password: string) => {
      try {
        await authService.signIn({ username: email, password });
      } catch (error) {
        if (error?.message === 'There is already a signed in user.') {
          console.error(error);
        } else {
          throw error;
        }
      }

      const userAttributes = await authService.fetchUserAttributes();

      const { idToken, accessToken } = await authService.getTokens();

      if (meAction) {
        const { allowed_pages, user, navigation_pages } = await httpClient.request<GetMeRes>(meAction);

        setSentryUser(user);
        AmplitudeService.setUser(user);
        LocalStorage.setItem(StorageKeysType.User, user);

        dispatch({
          type: Types.LOGIN,
          payload: {
            user: {
              ...user,
              ...userAttributes,
              idToken,
              accessToken,
              id: userAttributes.sub,
              displayName: `${user.first_name} ${user.last_name}`,
            },
            allowedPages: allowed_pages,
            navigationPages: navigation_pages,
          },
        });
      } else {
        dispatch({
          type: Types.LOGIN,
          payload: { user: null, allowedPages: [], navigationPages: [] },
        });
      }
    },
    [meAction]
  );

  // REGISTER
  const register = useCallback(async (args: SignUpInput) => authService.signUp(args), []);

  // LOGOUT
  const logout = useCallback(async () => {
    await authService.signOut();
    resetSentryUser();
    AmplitudeService.reset();
    LocalStorage.removeItem(StorageKeysType.User);
    LocalStorage.clear();
    sessionStorage.clear();
    StorageService.clear();
    dispatch({ type: Types.LOGOUT });
    mutate(() => true, undefined, { revalidate: false }); // clear all cache using swr
  }, [mutate]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      allowedPages: state.allowedPages,
      navigationPages: state.navigationPages,
      method: AuthMethodType.Amplify,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [
      status,
      state.user,
      state.allowedPages,
      state.navigationPages,
      //
      login,
      register,
      logout,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
