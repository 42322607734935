import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

import { useResponsive } from 'src/hooks/use-responsive';

import { bgGradient } from 'src/theme/css';
import { useTranslate } from 'src/locales';

import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children, image, title }: Props) {
  const theme = useTheme();

  const { t } = useTranslate();

  const mdUp = useResponsive('up', 'md');

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        pb: { xs: 15, md: 0 },
        my: 'auto',
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems='center'
      justifyContent='center'
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, theme.palette.mode === 'light' ? 0.88 : 0.94),
          imgUrl: '/assets/background/overlay_2.jpg',
        }),
      }}
    >
      <Typography variant='h3' sx={{ maxWidth: 480, textAlign: 'center' }}>
        {title || t('auth.welcome_back')}
      </Typography>

      {image && <Box component='img' alt='auth' src={image} sx={{ maxWidth: { xs: 480 } }} />}
    </Stack>
  );

  return (
    <Stack component='main' direction='row' sx={{ height: 1 }}>
      {renderLogo}

      {mdUp && renderSection}

      {renderContent}
    </Stack>
  );
}
