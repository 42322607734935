export enum EventTypes {
  // Canvas Thread Events
  THREADS_INITIALIZED = 'canvas:threads:initialized',
  THREADS_HOVER = 'canvas:threads:hover',
  THREADS_CLICK = 'canvas:threads:click',
  THREADS_UPDATE_FEATURES = 'canvas:threads:update-features',
  THREADS_RESET = 'canvas:threads:reset',

  // Canvas Toolbar Events
  TOOLBAR_SELECT_TOOL = 'canvas:toolbar:choose-tool',
  TOOLBAR_TOOL_SELECTED = 'canvas:toolbar:tool-selected',

  // Auto Annotate Events
  CANVAS_AUTO_ANNOTATE_CLICKED = 'canvas:tools:auto-annotate-clicked',
  CANVAS_AUTO_ANNOTATE_STARTED = 'canvas:tools:auto-annotate-started',
  CANVAS_AUTO_ANNOTATE_COMPLETED = 'canvas:tools:auto-annotate-completed',
  PROJECT_AUTO_ANNOTATE_STARTED = 'project:auto-annotate-completed',

  // Canvas Viewer
  VIEWER_OPEN_CONTEXT_MENU = 'canvas:viewer:open-context-menu',
  VIEWER_CHANGE_ZOOM = 'canvas:viewer:change-zoom', // TODO: remove this event after moving into canvas-v4
  VIEWER_ZOOM_CHANGED = 'canvas:viewer:zoom-changed', // TODO: remove this event after moving into canvas-v4

  // Canvas Interaction Events
  INTERACTION_SELECT_ANNOTATION = 'canvas:interaction:select-annotation',

  // Canvas General Events
  CANVAS_CLEANUP = 'canvas:cleanup',

  // Canvas Page
  CANVAS_PAGE_UNMOUNT = 'canvas:page:unmount',

  // Canvas Map Events
  MAP_CLICK = 'canvas:map:click',

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  // Auth
  AUTH_SIGN_OUT = 'auth:sign-out',

  // Annotations
  ANNOTATIONS_COUNT_UPDATE = 'annotation:count:update',
}
