import { useState } from 'react';
import { m } from 'framer-motion';

import { Button, Accordion, Typography, AccordionSummary, AccordionDetails } from '@mui/material';

import { RouterLink } from 'src/routes/components';

import ThemeProvider from 'src/theme';
import AuthClassicLayout from 'src/layouts/auth/classic';
import { SeverErrorIllustration } from 'src/assets/illustrations';

import Iconify from 'src/components/iconify';
import { defaultSettings } from 'src/components/settings/data';
import { SettingsProvider } from 'src/components/settings/context';
import { varBounce, MotionContainer } from 'src/components/animate';

// ----------------------------------------------------------------------

interface Props {
  error: Error | unknown;
  componentStack: string;
  resetError: () => void;
}

export default function ViewErrorFallback({ error, componentStack, resetError }: Props) {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <SettingsProvider defaultSettings={defaultSettings}>
      <ThemeProvider>
        <AuthClassicLayout title='Oops! Something went wrong'>
          <MotionContainer display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
            <m.div variants={varBounce().in}>
              <Typography sx={{ color: 'text.secondary' }}>An unexpected error occurred. Please try again.</Typography>
            </m.div>

            <Accordion
              expanded={showDetails}
              onChange={() => setShowDetails(!showDetails)}
              sx={{ marginTop: '20px', width: '100%' }}
            >
              <AccordionSummary expandIcon={<Iconify icon='eva:arrow-ios-downward-fill' />}>
                <Typography variant='body2' color='primary'>
                  See Details
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography variant='body2' color='error'>
                  {error?.toString() ?? 'No error details available'}
                </Typography>
                <Typography display='none' variant='body2' color='text.secondary'>
                  {componentStack}
                </Typography>
              </AccordionDetails>
            </Accordion>

            <m.div variants={varBounce().in}>
              <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
            </m.div>

            <Button
              component={RouterLink}
              href='/'
              size='large'
              variant='contained'
              title='Try Again'
              onClick={resetError}
              endIcon={<Iconify icon='eva:arrow-circle-right-fill' />}
            >
              Homepage
            </Button>
          </MotionContainer>
        </AuthClassicLayout>
      </ThemeProvider>
    </SettingsProvider>
  );
}
