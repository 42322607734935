/* eslint-disable perfectionist/sort-imports */
import 'src/global.css';

// i18n
import 'src/locales/i18n';

// ----------------------------------------------------------------------

import React, { useEffect } from 'react';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { useAppInit } from 'src/services/api/hooks/use-app-init';

import { LocalizationProvider } from 'src/locales';
import { AuthProvider } from 'src/services/auth/context';
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsDrawer, defaultSettings, SettingsProvider } from 'src/components/settings';

import { SplashScreen } from 'src/components/loading-screen';
import { useAppSelector } from 'src/store/hooks';
import ThemeProvider from 'src/theme';
import { withSentryWrapper } from 'src/services/sentry';
import RouterNavigateSetter from 'src/routes/components/router-navigate-setter';
import useUnsavedChangesWarning from 'src/hooks/use-unsaved-changes-warning';

// ----------------------------------------------------------------------

interface Props {
  children?: React.ReactNode;
}

function App({ children }: Props) {
  useScrollToTop();

  const { appLoading } = useAppSelector(({ app }) => app);
  const { writeApiCallsPending } = useAppSelector(({ app }) => app);
  const { isLoading: initializing, error: initializationError } = useAppInit();
  const { setEnabled: setUnsavedChangesWarning } = useUnsavedChangesWarning(Boolean(writeApiCallsPending));

  useEffect(() => {
    const showWarningAlert = writeApiCallsPending !== 0;
    setUnsavedChangesWarning(showWarningAlert);
  }, [writeApiCallsPending, setUnsavedChangesWarning]);

  if (initializationError) {
    throw new Error(`App Initialization Error: ${initializationError}`);
  }

  return (
    <LocalizationProvider>
      <SettingsProvider defaultSettings={defaultSettings}>
        <ThemeProvider>
          <MotionLazy>
            {initializing ? (
              <SplashScreen />
            ) : (
              <SnackbarProvider>
                <AuthProvider>
                  <RouterNavigateSetter />
                  <SettingsDrawer />
                  <ProgressBar />
                  {children}
                </AuthProvider>
              </SnackbarProvider>
            )}
          </MotionLazy>
        </ThemeProvider>
      </SettingsProvider>
    </LocalizationProvider>
  );
}

export default withSentryWrapper(App);
