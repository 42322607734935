import { DeviceType } from 'src/utils/user-agent';

import { LocalStorage } from 'src/helpers/local-storage';

import { UserRoleType } from 'src/types/types-user';

import { APP_VERSION } from '../config-global';
import { StorageKeysType } from '../types/enum-storage-keys';
import { FeatureType } from '../feature-flags/enum-features';

import { ConfigBase } from './config-base';

// ----------------------------------------------------------------------

interface FeatureOptions {
  enabled: boolean | { [k in DeviceType]: boolean };
  accessRoles?: UserRoleType[];
  dependencies?: FeatureType[];
  minVersion?: string;
}

export class ConfigFeature extends ConfigBase<FeatureOptions> {
  get isEnabled() {
    return this.isFeatureEnabled() && this.canAccessFeature() && this.isFeatureVersionCompatible();
  }

  get accessibleRoles() {
    return this.getSetup().accessRoles ?? Object.values(UserRoleType);
  }

  // Method to get whether the feature is enabled for the current environment
  private isFeatureEnabled(): boolean {
    const config = this.getSetup();
    return this.isDeviceConfig<boolean>(config.enabled) ? config.enabled[this.device] : config.enabled;
  }

  // Check role-based access for the feature
  private canAccessFeature(): boolean {
    const user = LocalStorage.getItem(StorageKeysType.User);
    const config = this.getSetup();

    if (!user) return false;
    if (!config.accessRoles) return true;

    return config.accessRoles.includes(user.role);
  }

  // Ensure version compatibility
  private isFeatureVersionCompatible(): boolean {
    const config = this.getSetup();

    if (!config.minVersion) return true;

    return this.isVersionGreaterOrEqual(APP_VERSION, config.minVersion);
  }

  // Private helper to compare versions
  // eslint-disable-next-line class-methods-use-this
  private isVersionGreaterOrEqual(version: string, minVersion: string): boolean {
    // Version comparison logic
    return true; // Dummy logic for simplicity
  }
}
